@import "bootstrap-v4-override";
@import "variables";
@import "mixins";
@import "checkbox-toggle";
@import "widget";
@import "license-activation";
@import "notifications";
@import "date-range";

.checkbox input[type=checkbox]:checked + label:after {
    font-family: FontAwesome;
    content: "\f00c";
}

.checkbox label:after {
    padding-left: 4px;
    padding-top: 2px;
    font-size: 9px;
}

.checkbox--inline {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 3px;
    text-align: left;
}

/*
 * LAYOUT
 */
.layout__main-sidebar {
    position: fixed;
    width: 45px;
    height: 100%;
    z-index: 1000;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    background: rgba(53, 64, 82, 1);
    background: linear-gradient(to bottom, rgba(53, 64, 82, 1) 0%, rgba(33, 40, 51, 1) 100%);
}

.layout__main-panel {
    position: fixed;
    right: 0;
    left: 45px;
}

.menu__label {
    display: none;
}

/** Sidebar */

.sidebar__logo {
    width: 25px;
}

.sidebar__logo-panel {
    height: $navbar-header-height;
    border-bottom: 1px solid $sidebar-border-color;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-top: 28px;
}

@media (min-width: 1024px) {

    /** Layout */
    .layout__main-sidebar {
        width: 150px;
    }

    .layout__main-panel {
        left: 150px;
    }

    .menu__icon {
        vertical-align: middle;
    }

    .menu__label {
        display: inline-block;
        vertical-align: middle;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /** Sidebar */
    .sidebar__logo {
        width: 60px;
    }

    .sidebar__logo-panel {
        padding-top: 24px;
    }

}

/* Sidebar navigation */
.nav-sidebar {
    $margin: 20px;
    $border: 1px;
    margin-right: - $margin - $border;
}

.nav-sidebar > li {
    border-bottom: 1px solid $sidebar-border-color;
}

.nav-sidebar > li > a {
    display: block;
    padding: $sidebar-vertical-padding 14px;
    color: #d9dade;
    border-left: 3px solid transparent;
    font-size: 15px;
    font-weight: normal;
    max-width: 150px;
    line-height: 1.2em;
}
.nav-sidebar > li > a {
    &.shrinked {
        padding: $sidebar-vertical-padding 4px;
    }
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #151a20;
    border-left: 3px solid $sidebar-border-color-active;
}

.nav-sidebar > li > a:active,
.nav-sidebar > li > a:focus,
.nav-sidebar > li > a:hover {
    color: #fff;
    background-color: #2a2a2b;
    border-left: 3px solid $sidebar-border-color-hover;
}

.nav-sidebar__nav-delimiter {
    color: rgb(143,147,154);
    text-transform: uppercase;
    padding: 20px 8px 5px 8px;
    font-size: 0.8rem;
}

.nav-sidebar__integration-icon {
    width: 16px;
    height: 16px;
    margin-right: 0.2rem;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 16px 16px;
}

.nav-sidebar__integration-icon--dispatcher-phoenix {
    background-image: url("../img/dp_logo.png");
}

/*
 * Header
 */

.layout__main-header {
    padding: 0 0 0 0;
    top: 0;
    z-index: 1000;
}

.layout__main-subheader {
    border-bottom: 1px solid $navbar-border-color-lighter;
    padding: 0 0 0 0;
    background: #fff;
    top: $navbar-header-height;
    z-index: 999;
}

.layout__main-subheader--shadow {
    box-shadow: 0 0 5px $navbar-border-color-lighter;
}

.navbar-default {
    background: #fff;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $navbar-border-color-lighter;
    border-left: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.dropdown-menu a {
    cursor: pointer; /* Fix for links without "href" */
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
    color: #c5c5c5;
}

.dropdown-toggle__no-caret::after {
    border: 0;
}

.flag-icon__language-selection,
.flag-icon__language-selection {
    display: inline-block;
}

.flag-icon__language-selection {
    margin-right: 20px;
}

.language-switch__current-language {
    margin-left: 5px;
}

.alert--header {
    margin: 20px 0;
}

/**
 * Main
 */
.layout__main-content {
    position: absolute;
    padding: 120px 20px 20px 20px;
}

.layout__panel-search,
.layout__panel-content {
    border: 1px solid $content-border-color;
    border-radius: 3px;
    background: #fff;
    padding: 1rem;
}

.layout__panel-actions {
    margin: $panel-top-margin 0 0 0;
}

.layout__panel-content {
    margin: $panel-top-margin 0 3rem 0;
}

.layout__panel-search {
    padding: 2rem 1rem;
    margin: $panel-top-margin 0 0 0;
}

.layout__alert-activation {
    margin-top: $panel-top-margin;
}

.layout__alert-activation-message {
    padding-top: 1.5rem;
}

.layout-clean-container {
    background: inherit;
    border: none;
    padding: 0;
}

/**
 * Buttons
 */

.actions--btn, .actions--btn-group-item {
    padding: 0.7em 1em;
    text-transform: uppercase;
}

.actions--btn:not(:first-child) {
    margin-left: 0.5rem;
}

.actions--icon {
    padding-right: 0.5em;
}

.btn-plain:active,
.btn-plain:focus,
.btn-plain:hover,
.btn-plain {
    background: transparent;
    border: 0;
    color: $gray-dark;
}

.buttons-panel {
    padding-bottom: 30px;
}

.filter-panel {
    padding: 30px;
    border: 1px solid $content-border-color;
    background: #fff;
    margin-bottom: 30px;
}

.nav-secondary {
    padding-left: 2em;
}

.nav-secondary .nav-secondary-item,
.nav-secondary {
    white-space: nowrap;
}

.nav-secondary .nav-secondary-item {
    display: inline-block;
    float: none;
}

.nav-secondary .dropdown-menu .nav-secondary-item {
    display: block;
    float: left;
    width: 100%;
}

.vertical-layout--icon-bar {
    display: block;
    height: 22px;
    border-radius: 1px;
    background-color: #888;
    float: left;
    margin: 0 1px;
}

.btn-vertical-layout__active .vertical-layout--icon-bar {
    background-color: #393939;
}

.vertical-layout--icon-bar__half {
    width: 8px;
}

.vertical-layout--icon-bar__third {
    width: 6px;
}

.vertical-layout--icon-bar__quarter {
    width: 4px;
}

.btn-vertical-layout {
    background: none;
    padding: 6px 4px;
}

.nav-secondary-item .nav-secondary-link {
    padding: 7px 25px;
    border-radius: 0;
    border-bottom: 3px solid #fff;
    color: gray;
}

.nav-secondary-item .nav-secondary-link--toggle {
    padding: 7px 10px;
}

.nav-secondary-item .nav-secondary-link:focus,
.nav-secondary-item .nav-secondary-link:active,
.nav-secondary-item .nav-secondary-link:hover {
    border-bottom: 3px solid $sidebar-border-color-hover;
}

.nav-secondary-item.active .nav-secondary-link {
    background: none;
    color: inherit;
    font-weight: bold;
    border-bottom: 3px solid $sidebar-border-color-active;
    border-radius: 0;
}

.nav-secondary-item.active .nav-secondary-link:focus,
.nav-secondary-item.active .nav-secondary-link:active,
.nav-secondary-item.active .nav-secondary-link:hover {
    border-bottom: 3px solid $sidebar-border-color-active;
    background: none;
    color: inherit;
}

.layout__main-header .breadcrumb {
    background: none;
    margin-bottom: 0;
    white-space: nowrap;
}

.breadcrumb__item,
.layout__main-header .breadcrumb a {
    color: inherit;
    font-size: inherit;
    line-height: 2.5em;
}

.layout__main-header .breadcrumb li {
    font-size: 1.6em;
    float: none;
    display: inline-block;
}

.layout__main-header .breadcrumb li.hidden {
    display: none;
}

.layout__main-header .breadcrumb > li + li:before {
    content: "";
    font-size: 80%;
}

.layout__main-header .breadcrumb > li:not(.hidden) + li:before {
    content: "\276F";
}

.layout__main-header .breadcrumb > li.active {
    color: inherit;
}

.layout__main-header .breadcrumb > li:not(.hidden) + li.active {
    color: $gray-light;
}

.navbar-default .dropdown:not(:last-child) {
    border-right: 1px solid $navbar-border-color;
}

.container-fluid--header {
    padding-right: 0;
}

@media (max-width: 768px) {
    .layout__main-header .breadcrumb > li + li:before {
        display: none;
    }
}

@media (max-width: $navbar-breakpoint-max) {
    .layout__main-header .breadcrumb {
        height: $navbar-header-height - 1px;
    }

    .navbar-collapse .nav .open > a,
    .navbar-collapse .nav .open > a:focus,
    .navbar-collapse .nav .open > a:hover {
        border-color: $navbar-border-color;
    }

    .navbar-nav > li > a,
    .navbar-nav .open .dropdown-menu {
        border-bottom: 1px solid $navbar-border-color;
    }

    .navbar-nav {
        border-top: 1px solid $navbar-border-color;
    }

    .navbar-nav > li.navbar-link-xs--last > a {
        border-bottom: none;
    }

    .mobile-navigation .navbar-nav .dropdown:not(:last-child) {
        border-right: none;
    }

    .mobile-navigation {
        overflow-y: auto;
        overflow-x: visible;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.layout__main-navbar-header {
    min-height: $navbar-header-height - 2px;
}

.navbar-default .dropdown {
    font-size: 1.2em;
}

.navbar-default .navbar-nav > li > a {
    line-height: 67px;
    height: $navbar-header-height - 1px;
}

.navbar-default .dropdown-menu > li > a {
    font-size: 1.2em;
}

.dropdown-menu--header > li > a {
    padding: 8px 20px;
}

.dropdown-menu--no-hover .dropdown-item {
    padding: 0;
}

.dropdown-menu--no-hover .dropdown-item:focus,
.dropdown-menu--no-hover .dropdown-item:hover {
    color: inherit;
    background-color: inherit;
}

.dropdown-item--wrap-white-space {
    white-space: normal;
}

.navbar-default {
    border-color: $navbar-border-color;
}

#bootstrap-overrides .navbar-user-profile {
    line-height: 1;
    display: table-row;
    height: 79px;
}

.navbar-user-profile__column {
    display: table-cell;
    vertical-align: middle;
    padding: 0 0 2px 0;
}

.navbar-user-profile__column--first {
    padding-right: 10px;
    padding-left: 1rem;
}

.navbar-user-profile__column--last {
    padding-left: 7px;
    padding-right: 10px;
}

.navbar-default--ysoft .dropdown .dropdown-menu > li > a,
.navbar-default--ysoft .dropdown {
    font-size: 1.1em;
}

.navbar-toggle--ysoft {
    margin-top: 18px;
    margin-right: 30px;
}

@media (min-width: 768px) {
    .dropdown-menu__scrollable {
        height: auto;
        max-height: 400px;
        overflow-x: hidden;
    }
}

.navbar__javascript-error {
    color: #f13535;
    font-size: 2.5rem;
    line-height: 5.5rem;
    cursor: default;
}

/*
Tables
*/

.table > thead > tr > th,
.table > tbody > tr > th {
    background: #e6faff;
    border-top: 0;
    border-bottom: 1px solid $navbar-border-color;
    font-weight: 500;
}

.table > tbody > tr > td {
    border-bottom: 1px solid #edf0f2;
    border-top: 0;
    vertical-align: middle;
}

.table-striped > tbody > tr:nth-child(odd) {
    background-color: $table-row-color-highlight;
}

.table > tbody > tr > td .btn {
    font-size: 1em;
}

/* Pager */

.pagination--wrapper {
    overflow: hidden;
}

.pagination--wrapper .pagination {
    margin: 0;
}

/* Forms */

.input--inner-addon {
    position: relative;
}

.input-inner-addon__input {
    padding-right: 35px;
}

.input-inner-addon__icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 10px;
    cursor: pointer;
}

#bootstrap-overrides .text-medium {
    font-weight: 500;
}

#bootstrap-overrides .text-light {
    font-weight: 300;
}

/** change the color of inline icon when WebKit sets the yellow background after the autocomplete was applied
(see https://code.google.com/p/chromium/issues/detail?id=46543) */
.input-inner-addon__input:-webkit-autofill + .input-inner-addon__icon {
    color: #000;
}

/* Entity list */

.entity-list .entity-list__header--not-last {
    border-bottom: 1px solid #ddedf1;
}

.entity-list .entity-list__header {
    vertical-align: middle;
}

.entity-list__header--icon {
    width: 20px;
}

.entity-list__fakelink,
.entity-list__header--fakelink {
    cursor: pointer;
}

.entity-list__header--fakelink:hover {
    text-decoration: underline;
}

.entity-list__row:nth-child(odd) {
    background-color: $table-row-color-highlight;
}

.entity-list__row:nth-child(even) {
    background-color: $table-row-color-even;
}

.entity-list__column-selected {
    background-color: $table-row-color-selected;
}

.entity-list .entity-list__column--actions {
    padding: 0;
}

.entity-list__clickable-column:hover {
    background-color: $table-row-color-selected;
}

.entity-list__clickable-column-link {
    display: block;
    height: 100%;
    width: 100%;
}

.entity-list__link--actions,
.entity-list__actions {
    padding: 0 5px;
    white-space: nowrap;
}

.entity-list__body--fixed {
    overflow-y: auto;
    max-height: 300px;
    display: block;
}

.entity-list__row--fixed,
.entity-list__column--fixed {
    display: block;
}

.entity-list__link--actions-caret {
    padding: 0;
}

.entity-list__actions {
    border-left: 1px solid #edf0f2;
    display: inline-block;
    vertical-align: middle;
}

.entity-list__link--actions,
.entity-list__link--actions:active,
.entity-list__link--actions:visited,
.entity-list__link--actions:hover {
    color: $entity-actions-link-color;
    text-decoration: none;
    float: left;
}

.entity-list__link--actions {
    text-transform: uppercase;
    color: $entity-actions-link-color;
    display: inline-block;
    font-weight: 500;
    margin: 10px 0;
}

.entity-list__link--actions:hover {
    background-color: $entity-actions-link-hover;
}

.entity-list_search-button {
    padding-left: 0;
}

.entity-list__search-buttons {
    @extend %clearfix;
}

.hidden--soft {
    display: none;
}

/* Filter panel */

.input-group .form-control--time-field {
    width: 40%;
    margin-right: 5%;
}

.input-group .form-control--date-field {
    width: 55%;
    margin-right: 0;
}

.input-group-addon--break-chain-set {
    border-left: 0;
}

.btn--search {
    @extend .text-uppercase;
}

/* Render gray dashed line under the labels that display tooltip (workaround for Chrome unsupported CSS3 rule text-decoration-style/color */

.label--tooltip {
    position: relative;
    cursor: help;
}

.label--tooltip:before {
    content: "- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -";
    font-size: 0.6rem;
    color: #d0d0d0;
    width: 100%;
    position: absolute;
    top: 12px;
    overflow: hidden;
    white-space: nowrap;
}

.label--required:after {
    display: inline-block;
    content: "*";
    color: #f11717;
    margin-right: -0.7rem;
    padding-left: 0.3rem;
}

/** Fix <body> shift when modal is displayed and page has scrollbar */

.modal {
    overflow-y: auto !important;
}

.modal-open {
    overflow: auto !important;
    overflow-x: hidden !important;
    padding-right: 0 !important;
}

.btn--modal {
    text-transform: uppercase;
}

.modal-body__search {
    padding-bottom: 15px;
}

#delete-confirmation-message {
    word-break: break-word;
}

/* Bootstrap v4 alpha 2 */

@media (max-width: $navbar-breakpoint-max) {
    .navbar-nav .nav-item {
        float: none;
    }
}

@media (max-width: $navbar-breakpoint-max) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

.dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.nav-pills .nav-item.open .nav-link, .nav-pills .nav-item.open .nav-link:focus, .nav-pills .nav-item.open .nav-link:hover,
.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover {
    color: #262626;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
}

.nav-pills .nav-item.open .nav-link:focus, .nav-pills .nav-item.open .nav-link:hover,
.nav-pills .nav-link.active, .nav-pills .nav-link.active:focus, .nav-pills .nav-link.active:hover {
    background-color: #f5f5f5;
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
    background-color: #eee;
    border-color: #337ab7;
}

.nav-pills .nav-item + .nav-item {
    margin-left: 0;
}

.form-horizontal .form-control-label {
    text-align: right;
}

legend {
    border-bottom: 1px solid #e5e5e5;
}

.has-danger ul.text-muted {
    list-style: none;
    padding-left: 0;
}

.is-required .select2-container--default .select2-selection--single,
.form-control--required {
    background-color: #f0fbff;
}

.date-picker__button {
    cursor: pointer;
}

.layout-actions__go-back {
    font-size: 1.3em;
    padding-left: 0.5em;
    line-height: 3rem;
}

.form-control--no-border {
    border: 0;
}

.actions--filter {
    padding-top: 0.3rem;
    float: left;
}

.actions--filter label {
    padding: 0.3rem 1.5rem 0 0;
    float: left;
}

.actions--filter select {
    width: auto;
}

.progress--condensed {
    margin-bottom: 0;
}

.flag > .form-group > .fa-lg {
    line-height: normal;
}

.flag > .form-group {
    margin-bottom: auto;
}

.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.password--unchanged {
    color: $placeholder-text-color;
}