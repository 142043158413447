@import "variables";

$invalid-value-color: #ce4b48;
$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #818a91;
$panel-background-color: #fff;

/* Invalid fields must use darker red color */

.has-danger .checkbox,
.has-danger .checkbox-inline,
.has-danger .form-control-label,
.has-danger .radio,
.has-danger .radio-inline,
.has-danger .text-help,
.has-danger .text-muted,
.has-danger.checkbox label,
.has-danger.checkbox-inline label,
.has-danger.radio label,
.has-danger.radio-inline label {
    color: $invalid-value-color;
}

.has-danger .form-control {
    border-color: $invalid-value-color;
}

/* Dropdown button links must be gray, not blue */

.dropdown .nav-link {
    color: $gray-dark;
}

.dropdown .nav-link:hover {
    color: $gray;
}

/* Nav bar dropdown must have hover effect across whole dropdown area */

.navbar-nav .nav-item + .nav-item {
    margin-left: 0;
}

.navbar-default .navbar-nav > li > a {
    padding-left: 1rem;
    padding-right: 0.5rem;
}

/* Selected tab ear must be white as in Bootstrap docs, not gray */

.nav-tabs .nav-link.active {
    background-color: $panel-background-color;
}

/* Dropdown header must be closer to it's group not to the previous one */

.dropdown-header {
    padding: 10px 20px 0 20px;
}

/* Missing style in Bootstrap v4, required for the Date Picker plugin */

.bootstrap-datetimepicker-widget .table-condensed > thead > tr > th,
.bootstrap-datetimepicker-widget .table-condensed > tbody > tr > th,
.bootstrap-datetimepicker-widget .table-condensed > tfoot > tr > th,
.bootstrap-datetimepicker-widget .table-condensed > thead > tr > td,
.bootstrap-datetimepicker-widget .table-condensed > tbody > tr > td,
.bootstrap-datetimepicker-widget .table-condensed > tfoot > tr > td {
    padding: 5px;
}

/* There must be a space under the tabs */

.tab-content {
    padding-top: 1rem;
}

/* Active button group button must have blue color */

.btn-group .btn-secondary.active {
    color: #fff;
    background-color: $brand-primary;
    border-color: $brand-primary;
}