/*
 * Header - notifications
 */

$notification-item-background: #fff;
$notification-item-border-color: #edf0f2;
$notification-content-padding: 20px;

.notifications-icon__badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    background: $brand-danger;
    position: absolute;
    top: 1em;
    left: 1.6em;
}

.notifications {
    min-width: 300px;
    padding-top: 0;
    padding-bottom: 0;
}

.notifications-type {
    list-style: none;
    background: #f0f0f0;
    line-height: 2.5em;
    padding-left: $notification-content-padding;
    text-transform: uppercase;
    border-top: 1px solid $navbar-border-color-lighter;
    border-bottom: 1px solid $navbar-border-color-lighter;
    overflow: hidden;
}

.notifications--item {
    display: block;
    padding-left: $notification-content-padding;
    font-size: 1em;
}

.notifications--item__heading {
    list-style: none;
    padding-left: $notification-content-padding;
    line-height: 3em;
    font-size: 1.2em;
}

.notifications--item:not(:last-child),
.notifications-list__item:not(:last-child),
.notifications-type {
    list-style: none;
}

.notifications--item:not(:last-child),
.notifications-list__item:not(:last-child) {
    border-bottom: 1px solid $notification-item-border-color;
}

.notifications-list__item--expanded {
    border-bottom: none;
}

.notifications-list__row {
    display: table-row;
}

.notifications-list__content,
.notifications-list__icon,
.notifications-list__icon--small {
    display: table-cell;
    vertical-align: middle;
}

.notifications-list__icon {
    width: 2em;
    font-size: 2em;
    line-height: 2em;
    text-align: center;
    padding: 0 0.5em 0 0.5em;
}

.notifications-list__icon--small {
    font-size: 1em;
    text-align: right;
    padding: 0 1em 0 0;
}

.notifications-list__icon--read {
    opacity: 0.4;
}

.notifications-list__content {
    line-height: 1.5em;
    font-size: 0.75em;
    width: 100%;
}

.notifications-list__items-categories {
    max-height: 250px;
    overflow-y: scroll;
}

.notifications-list__items {
    list-style: none;
    background: $notification-item-background;
    padding-left: 0;
    white-space: normal;
    min-width: 350px;
}

.notifications-list__item {
    padding: 0.3rem 0 0.3rem 0
}

.notifications-list__title--read {
    font-weight: normal;
}

.notifications-list__link, .notifications-list__link:hover {
    cursor: pointer;
}

.notifications-list__link:hover {
    background-color: darken($notification-item-background, 2%);
}

.notification__message {
    margin-bottom: 0;
}

.notification__SUCCESS {
    color: $brand-success;
}

.notification__ERROR {
    color: $brand-danger;
}

.notification__WARNING {
    color: $brand-warning;
}

.notification-icon__SUCCESS:before {
    content: "\f058";
}

.notification-icon__ERROR:before {
    content: "\f057";
}

.notification-icon__RUNNING_TASK:before {
    content: "\f021";
}

.notification-icon__RUNNING_TASK {
    animation: spin 1500ms infinite linear;
}

.notification-icon__WARNING:before {
    content: "\f071";
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

#bootstrap-overrides .header__notifications {
    margin-left: 0;
}

.dropdown-toggle__notifications {
    padding-left: 1rem;
}

.header__notifications--small {
    position: relative;
    float: right;
    margin-top: 18px;
    margin-right: 15px;
    margin-bottom: 8px;
    background: transparent none;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 34px;
    width: 44px;
    text-align: center;
}

.dropdown-toggle__notifications--small {
    display: inline-block;
    padding-top: 0.25rem;
    padding-left: 0.75rem;
}

.header__notifications--small:hover {
    background-color: #ddd;
}

.notifications-type__link {
    color: $gray-dark;
}

.notifications-type__link:hover {
    color: $gray;
}

.notifications-type__links {
    margin-right: 1rem;
    color: $gray-light;
}

.notifications-list__message {
    background: $body-bg;
    border-top: 1px solid $navbar-border-color-lighter;
    padding-left: $notification-content-padding;
    line-height: 2.5rem;
}